import { Input, Password } from "components/Input/Input";
import { Formik, Form } from "formik";
import React from "react";
import { NavLink } from "react-router-dom";

import classes from "./LoginForm.module.scss";

const LoginForm = ({ title = "Login" }) => {
  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <div className={classes.Login}>
      <div className={classes.LoginOverlay}></div>
      <div className={classes.LoginContainer}>
        <div className={classes.Title}>
          <p>{title}</p>
        </div>
        <div className={classes.Fields}>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className={classes.FormContainer}>
                <div>
                  <Input
                    name="email"
                    label="Email"
                    placeholder="Jessicajames@gmail.com"
                  />
                </div>
                <div>
                  <Password name="password" label="Password" />
                </div>
                <div className={classes.FooterButtons}>
                  <div>
                    <button type="submit" className=" btn-primary btn-brown">
                      Log-in
                    </button>
                  </div>
                  <div>
                    <NavLink
                      to="/register"
                      style={{ cursor: "pointer" }}
                      className={
                        classes.Submit + window.screen.width >= 600
                          ? " btn-primary"
                          : " btn-black"
                      }
                    >
                      Register
                    </NavLink>
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
