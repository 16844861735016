import React, { useState } from "react";

import { useField } from "formik";

import classes from "./Input.module.scss";

export const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label + " label"}>
        {label}
        {props.required ? <span style={{ color: "red" }}>*</span> : ""}
      </div>
      <div>
        <input type="text" {...field} {...props} required={false} />
        {meta.touched && meta.error ? (
          <div className="error-msg">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export const Password = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [type, setType] = useState("password");

  return (
    <div className={classes.InputContainer}>
      <div className={classes.Label}>
        {label} {props.required ? <span style={{ color: "red" }}>*</span> : ""}
      </div>
      <div className={classes.PasswordContainer}>
        <input type={type} {...field} {...props} required={false} />
        {type === "password" ? (
          <i
            class="fa fa-eye"
            aria-hidden="true"
            style={{ fontSize: "1vw", color: "darkgrey" }}
            onClick={() => setType("text")}
          />
        ) : (
          <i
            class="fa fa-eye-slash"
            aria-hidden="true"
            style={{ fontSize: "1vw", color: "darkgrey" }}
            onClick={() => setType("password")}
          />
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className="error-msg">{meta.error}</div>
      ) : null}
    </div>
  );
};
