import { COURSES } from "../../constants";
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";

// import Buldge from "../../assets/Buldge.png";
// import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
// import { AuthContext } from "../../context/AuthContext";
// import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import CourseSectionTitle from "../CourseSectionTitle/CourseSectionTitle";
import SuccessStories from "../SuccessStories/SuccessStories";
import SuccessStoriesTitle from "../SuccessStoriesTitle/SuccessStoriesTitle";
import Videos from "../Videos/Videos";
import Testimonials from "components/Testomonials/Testimonials";
import NewsLetter from "components/NewsLetter/NewsLetter";
import classes from "./CourseContent.module.scss";

const CourseContent = () => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [mainTitle, setMainTitle] = useState(
    "All the French, Spanish, IELTS Courses"
  );
  const [subMainTitle, setSubMainTitle] = useState("Students trust us!");

  //   const { state, dispatch } = useContext(AuthContext);

  // const courses = [
  //   {
  //     name: "French",
  //     description: "French Language Course",
  //     id: 1,
  //     // subDescription: ["48 Hrs", "₹14999"],
  //     img: AboutUs3,
  //   },
  //   {
  //     name: "Course 2",
  //     description: "Spanish Language Coursse",
  //     id: 2,
  //     // subDescription: ["Cras justo odio", "Cras justo odio", "Cras justo odio"],
  //     img: AboutUs1,
  //   },
  //   {
  //     name: "Course 3",
  //     description: "Crack IELTS, Get to Learn",
  //     id: 3,
  //     // subDescription: ["Cras justo odio", "Cras justo odio", "Cras justo odio"],
  //     img: AboutUs2,
  //   },
  // ];

  return (
    <div className={classes.CourseContent}>
      <div className={classes.courseContainer}>
        {Object.entries(COURSES).map(([key, course]) => (
          <Card
            onClick={() => history(`/course/${key}`)}
            key={course.name}
            className={classes.courseCard}
          >
            <Card.Img variant="top" className={classes.img} src={course.img} />
            <Card.Body>
              {/* <Card.Title>{course.title}</Card.Title> */}
              <Card.Text className={classes.courseTitle}>
                {course.name}
              </Card.Text>
              <Card.Link
                href="#"
                className={classes.courseLink}
              >
                Learn More
              </Card.Link>
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="border"></div>
      <SuccessStoriesTitle
        main={"Watch Us !"}
        // subMain={"How We will  be Moving Ahead"}
      />
      <Videos />
      <div className="border"></div>

      <div
        style={{
          width: "60%",
          margin: "auto",
        }}
      >
        <SuccessStoriesTitle main={"Success Stories !"} />
        <Testimonials />
        {""}

        <div className="border"></div>

        <NewsLetter />

        {/* 
        <SuccessStories /> */}
      </div>
    </div>
  );
};

export default CourseContent;
