import { useEffect, useState } from "react";

export const useMobile = () => {
  const [mobile, setMobile] = useState(
    document.documentElement.clientWidth <= 600
  );
  const handleResize = (e) => {
    setMobile(document.documentElement.clientWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return mobile
};
