import React, { useState } from "react";
import BgWithText from "../../BgWithText/BgWithText";
import CourseSection from "../../CoursesSection/CourseSection";
import LoginForm from "../../LoginForm/LoginForm";

const Home = () => {
  //   const history = useHistory();
  const [open, setOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [mainTitle, setMainTitle] = useState("World of Wordsmith");
  const [subMainTitle, setSubMainTitle] = useState("Students trust us!");

  //   const { state, dispatch } = useContext(AuthContext);

  return (
    <>
      <BgWithText
      // title="WordSmith"
      // image="https://import.cdn.thinkific.com/434513/custom_site_themes/355707/TFngH0t2RFeaRNSe2OwD_shutterstock_137802470__3____Copy_png"
      />
      <CourseSection />
    </>
  );
};

export default Home;
