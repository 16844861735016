import React, { useState } from "react";
import "./NewsLetter.scss";

const NewsLetter = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    console.log("hii");
  };

  return (
    <div>
      {/* <BgWithText title="About Us" image="bg2" /> */}
      <div className="Main">
        <section>
          <h1>Get Updates on Lessons every week!</h1>
          {/* <p>How to handle single field and a button with pure React.</p> */}

          <div>
            <input
              className={error ? "error" : ""}
              type="text"
              placeholder="Your email"
              value={email}
              onChange={(e) => {
                console.log(e);
              }}
            />
            <button
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </section>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default NewsLetter;
