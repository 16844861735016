import {
  RESUME_INFO,
  RESUME_INFO2,
  SCOPE_LIST,
  SCOPE_LIST2,
} from "../../../constants";
import classes from "./Faculties.module.scss";
import Expand from "react-expand-animated";
import VikashSharma from "assets/vikashSharma.jpeg";
import Mam from "assets/mam.jpeg";

import { useState } from "react";

const Faculties = () => {
  const [expand, setExpand] = useState(false);
  const [expand1, setExpand1] = useState(false);

  const transitions = ["height", "opacity", "background"];

  return (
    <div className={classes.Content}>
      <div className={classes.Container}>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className={classes.TextContainer}
              style={{ flexDirection: "column", display: "flex" }}
            >
              <div className="LargeText bold">
                <span className="brown">Our</span> Trainers
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.ResumeBox}>
        <div className={classes.ResumeContainer}>
          <div className={classes.Fields}>
            {RESUME_INFO.map((item) => (
              <div className={classes.FieldContainer}>
                <div className={classes.Property}>{item.field}</div>
                <div className={classes.Value}>{item.value}</div>
              </div>
            ))}
          </div>
          <div>
            <img className={classes.GraduateImage} src={VikashSharma} alt="" />
            <div
              className={classes.ReadMore + " btn-primary"}
              onClick={() => setExpand(!expand)}
            >
              {!expand ? "Read More" : "Read Less"}
            </div>
          </div>
        </div>
        <div className={classes.Expand}>
          <Expand open={expand} duration={1000} transitions={transitions}>
            {SCOPE_LIST.map((item, index) => (
              <div className={classes.SContainer}>
                {/* <div className={classes.SNo}>0{index + 1}</div> */}
                <div className={classes.SContent}>{item}</div>
              </div>
            ))}
            <p className={classes.FooterText}>Feel Free to reach out to me!</p>
          </Expand>
        </div>
        <div className={classes.ExpandMobile}>
          {SCOPE_LIST.map((item, index) => (
            <div className={classes.SContainer}>
              {/* <div className={classes.SNo}>0{index + 1}</div> */}
              <div className={classes.SContent}>{item}</div>
            </div>
          ))}
          <p className={classes.FooterText}>Feel Free to reach out to me!</p>
        </div>
      </div>
      <div className="border"></div>
      <div className={classes.ResumeBox}>
        <div className={classes.ResumeContainer}>
          <div className={classes.Fields}>
            {RESUME_INFO2.map((item) => (
              <div className={classes.FieldContainer}>
                <div className={classes.Property}>{item.field}</div>
                <div className={classes.Value}>{item.value}</div>
              </div>
            ))}
          </div>
          <div>
            <img className={classes.GraduateImage} src={Mam} alt="" />
            <div
              className={classes.ReadMore + " btn-primary"}
              onClick={() => setExpand1(!expand1)}
            >
              {!expand1 ? "Read More" : "Read Less"}
            </div>
          </div>
        </div>
        <div className={classes.Expand}>
          <Expand open={expand1} duration={1000} transitions={transitions}>
            {SCOPE_LIST2.map((item, index) => (
              <div className={classes.SContainer}>
                {/* <div className={classes.SNo}>0{index + 1}</div> */}
                <div className={classes.SContent}>{item}</div>
              </div>
            ))}
            <p className={classes.FooterText}>Feel Free to reach out to me!</p>
          </Expand>
        </div>
        <div className={classes.ExpandMobile}>
          {SCOPE_LIST2.map((item, index) => (
            <div className={classes.SContainer}>
              {/* <div className={classes.SNo}>0{index + 1}</div> */}
              <div className={classes.SContent}>{item}</div>
            </div>
          ))}
          <p className={classes.FooterText}>Feel Free to reach out to me!</p>
        </div>
      </div>
    </div>
  );
};

export default Faculties;
