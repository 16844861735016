import React from "react";

import { Input, Password } from "components/Input/Input";
import { Form, Formik } from "formik";
import { NavLink } from "react-router-dom";

import classes from "./RegisterForm.module.scss";

const RegisterForm = ({ title = "Register" }) => {
  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <div className={classes.RegisterForm}>
      <div className={classes.Register}>
        <div className={classes.RegisterOverlay}></div>
        <div className={classes.RegisterContainer}>
          <div className={classes.Title}>
            <p>{title}</p>
          </div>
          <div className={classes.Fields}>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className={classes.FormContainer}>
                  <div>
                    <Input
                      name="name"
                      label="Name"
                      placeholder="Jessica James"
                    />
                  </div>
                  <div>
                    <Input
                      name="email"
                      label="Email"
                      placeholder="Jessicajames@gmail.com"
                    />
                  </div>
                  <div>
                    <Password name="password" label="Password" />
                  </div>
                  <div>
                    <Password name="password" label="Confirm Password" />
                  </div>
                  <div className={classes.FooterButtons}>
                    <button type="submit" className=" btn-primary btn-brown">
                      Register
                    </button>
                    <NavLink
                      to="/login"
                      style={{ cursor: "pointer" }}
                      className={
                        classes.Submit + window.screen.width >= 600
                          ? " btn-primary"
                          : " btn-black"
                      }
                    >
                      Log-in
                    </NavLink>
                    <div></div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
