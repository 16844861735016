import React, { useEffect, useState } from "react";
import classes from "./BgWithText.module.scss";
import bannerImg from "assets/banner.png";
import mobileImg from "assets/mobileBanner.svg";

const BgWithText = ({ title }) => {
  const [mobile, setMobile] = useState(
    document.documentElement.clientWidth <= 600
  );
  const handleResize = (e) => {
    setMobile(document.documentElement.clientWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // === 'bg1' ? bg1 : image === 'bg2' ? bg2 : bg3;
  // if (mobile) BgImage = image === "bg1" ? bg1m : bg2m;
  return (
    <div
      className={classes.BgWithText}
      // style={{ background: mobile ? `url(${mobileImg})` : `url(${bannerImg})` }}
    >
      <img
        className={classes.overlayImg}
        src={mobile ? mobileImg : bannerImg}
        alt=""
      />
      {mobile ? (
        <div className={classes.TextContainer}>
          <div>
            <div className={classes.Title}>World</div>
            <div className={classes.Title}>Of</div>
            <div className={classes.Title}>Wordsmith</div>
          </div>
          <div>
            <h1 aria-label="New Language">
              New&nbsp;
              <span class={classes.typewriter}></span>
            </h1>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BgWithText;
