import React, { useEffect, useState } from "react";
import classes from "./Footer.module.scss";

const Footer = () => {
  const [eventDates, setEventDates] = useState([]);
  //   const history = useHistory();

  useEffect(() => {
    // getEventData();
  }, []);

  //   const getEventData = async () => {
  //     const { data } = await getAllEvents();
  //     const events = lodashGet(data, "data.data") || [];
  //     const eventDates = events.map((event) =>
  //       !event.isCancelled ? moment(event.startDate).format("YYYY-MM-DD") : null
  //     );
  //     const filteredDates = eventDates ? eventDates.filter(Boolean) : [];
  //     setEventDates(filteredDates);
  //   };
  //   const ulImage = <img src={UlArrow} alt="" />;
  return (
    <div className={classes.Footer}>
      <div className={classes.FooterContainer}>
        {/* <div className={classes.FooterDiv}>
          <div className={classes.FooterHeading}>Events Calendar</div>
          <div className={classes.CalendarContainer}>
            <SimpleReactCalendar
              weekStartsOn={0}
              activeMonth={new Date()}
              renderDay={(props) => {
                const hasEvent = eventDates.includes(props.ISODate);
                return (
                  <Day
                    {...props}
                    isToday={hasEvent}
                    isSelectable={hasEvent}
                    handleOnClick={() =>
                      hasEvent ? history.push("/events") : null
                    }
                  />
                );
              }}
            />
          </div>
        </div> */}
        <div className={classes.FooterDiv + " " + classes.SocialIcons}>
          <div className={classes.SocialIconsContainer}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/wordsmithclasses?igshid=ZDdkNTZiNTM="
            >
              <div className={classes.SocialIcon}>
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </div>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCfvU8W1e8u8z-Cg8tqaPXXg"
            >
              <div className={classes.SocialIcon}>
                <i class="fa fa-youtube" aria-hidden="true"></i>
              </div>
            </a>
          </div>
        </div>
        {/* <div className={classes.FooterDiv}>
          <div className={classes.FooterHeading}>
            <span>&nbsp;</span>
          </div>
          <div className={classes.SocialIconsContainer}>
            <img
              //   src={ScrollTopLogo}
              onClick={() => scroll.scrollToTop()}
              alt=""
            />
          </div>
        </div> */}
        <div className={classes.Copyrights}>
          Copyright © 2023 Wordsmith - All rights reserved.{" "}
        </div>
      </div>
    </div>
  );
};

export default Footer;
