import AboutUs1 from "assets/aboutUs.svg";
import AboutUs2 from "assets/aboutUs1.svg";

import AboutUs3 from "assets/aboutUs2.svg";
// import img from "assets/aboutUs.png";
// frenchCommon.svg;
import img from "assets/frenchCommon.svg";
import imgSpainish from "assets/spanishCommon.svg";
import imgIelts from "assets/ieltsCommon.svg";

// delfA1.svg;
import DELFA1 from "assets/delfA1.svg";
import DELFA2 from "assets/delfA2.svg";
import DELFB1 from "assets/delfB1.svg";
import DELFB2 from "assets/delfB2.svg";
import DELEA1 from "assets/DELEA1.svg";
import DELEA2 from "assets/DELEA2.svg";
import DELEB1 from "assets/DELEB1.svg";
import DELEB2 from "assets/DELEB2.svg";
import DELEC1 from "assets/DELEC1.svg";

import ACADEMIC from "assets/ieltsAcademic.svg";
import GENERAL from "assets/ieltsGeneral.svg";
import CELPIP from "assets/celpip.svg";
import TOELF from "assets/toefl.svg";

export const HEADER_LINKS = [
  { name: "Welcome", path: "/" },
  { name: "Our Trainers", path: "faculties" },
  // { name: "All Courses", path: "/#allCourses" },
  { name: "About Us", path: "aboutUs" },
];

export const PATHS = {
  HOME: "/",
  ABOUT_US: "aboutUs",
  SCHOOLS: "schools",
  BOOK_PRIZE: "bookPrize",
  SERVICES: "services",
  VOLUNTEER: "volunteer",
  JOIN_US: "joinUs",
  REGISTER: "register",
  PROFILE: "profile",
  PRIVACY_POLICY: "privacyPolicy",
  LOGIN: "auth/login",
  FORGET_PASSWORD: "auth/forgetPassword",
};

export const RESUME_INFO = [
  { field: "Name :", value: "Dr. Vikas Sharma" },
  {
    field: "Current Profession :",
    value:
      "Assistant Professor, Law, Maharishi University of Information Technology and Spanish instructor ",
  },
  {
    field: "Education Qualification :",
    value: "Ph.D. in Law and Master's in Spanish language ",
  },
  {
    field: "Contact :",
    value: " vikas230879@gmail.com",
  },
  {
    field: "Phone :",
    value: "9667788496",
  },
  {
    field: "Teaching experience :",
    value: "6+ years",
  },
  {
    field: "Mode Of Teaching :",
    value: "Online/Offline",
  },
];

export const SCOPE_LIST = [
  "¡Hola! My name is Dr. Vikas Sharma, and I'm an Assistant Professor of International Law at MUIT along with Spanish instructor with 6+ years of experience teaching Spanish to students of all ages and backgrounds. I'm a certified Spanish trainer, originally from India, and I'm passionate about sharing my language and culture with others.",
  "I received my language degree in 2016 from Instituto Hispania, and have since taught at various language institutes across India and abroad. I've worked with students ranging from young children to adults, and I've taught in a variety of settings, including schools, language institutes, corporate and private tutoring sessions.",
  "My teaching approach is based on building a strong foundation in the basics of Spanish grammar and vocabulary, while also providing opportunities for students to practice their speaking and listening skills in a real-world context. I believe that learning a language should be fun and engaging, so I incorporate games, songs, and other interactive activities into my lessons.",
  "In addition to teaching Spanish, I'm also knowledgeable about Spanish culture and history, and I incorporate these elements into my lessons as well. Whether you're a beginner just starting out or an advanced student looking to refine your skills, I'm committed to helping you achieve your language learning goals.",
  "If you're interested in learning Spanish or improving your existing skills, I invite you to explore the resources on my website, including [List any resources available on your website, such as lesson plans, activities, or study materials]. You can also contact me directly to schedule a private tutoring session or to learn more about my teaching approach. ¡Hasta pronto!",
];

export const RESUME_INFO2 = [
  { field: "Name :", value: "Sakshi Rawal" },
  {
    field: "Current Profession :",
    value: "Consultant in an IT company and French language teacher ",
  },
  {
    field: "Education Qualification :",
    value: "Master in management in IT and Master's in French language course ",
  },
  {
    field: "Contact :",
    value: "sakshirawal518@gmail.com",
  },
  {
    field: "Phone :",
    value: "9530298274",
  },
  {
    field: "Teaching experience :",
    value: "5+ years",
  },
  {
    field: "Mode Of Teaching :",
    value: "Online",
  },
];

export const SCOPE_LIST2 = [
  "Salut! My name is Sakshi Rawal and currently I am working as a Consultant in an IT Company and as a French language instructor. I have always been very passionate in learning new languages and French has been one of my favorite language. I am a certified French trainer and have 4+ years of experience in teaching French to both students and working professionals. I have learned French from Alliance Française and later did my PG diploma course in French from French as a Foreign Language (FLE) institute in 2017. ",
  "In past years, I have taught numerous students ranging from school going children, college students, adults who want to be multilingual by learning French and to people who want to immigrate to Francophone country especially Canada and France.",
  " I also prepare students for DELF(A1/A2/B1/B2) and TEF exam to help them achieve their immigration dream. I have worked with different institutes and Schools as a French language instructor and have provided one to one tutoring sessions to students who want to achieve a certain level of proficiency within limited amount of time.",
  "I offer individualized instructional material to each student according to his level, competence and goal. I use blended learning, which involves the use of traditional learning combined with technology to optimize student output. ",
  "The ultimate goal of my classes is to make learning enjoyable and interactive, while simultaneously striving to achieve the goal. Students are provided with Worksheets, Crosswords, Quiz, Assessment, Exercises, Recordings, Cheat sheets, Question Papers, Test Series, Videos and Audios. You can contact me to get a free demo class and learn more about my teaching methodology. Au revoir!!",
];

export const COURSES = {
  French: {
    name: "French Language Course",
    img: AboutUs3,
    tabs: [
      {
        name: "DELF A1",
        img: DELFA1,
        contents: [
          {
            title: "Title",
            desc: "Les Alphabets",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Presenting oneself and common French Greetings",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Numbers 1 to 20 and Days of the Week",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The subject pronouns and the verb `être`",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The verb `avoir` and the numbers 21-100",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The articles",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The adjectives",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The verb `aller`` and other -er verbs",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Exceptions of -er verbs",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Les prépositions (The prepositions)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The -ir verbs and the professions",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The negative sentences and the verb `faire`",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Les verbes vouloir, pouvoir, devoir et les verbes -tir (The verbs vouloir, pouvoir, devoir and -tir verbs)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The possessive, demonstrative and the interrogative adjectives",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The contracted and partitive articles",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "L'interrogation (The interrogation)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The regular and irregular RE verbs",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "The pronominal verbs and how to watch the time",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: " Les adverbes interrogatifs (The interrogative adverbs)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Savoir v/s Connaître",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: " L'impératif (The imperative sentences)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title",
            desc: "Le Passé Composé (Past tense)",
            url: "www.facebook.com",
            img,
          },
        ],
      },
      {
        name: "DELF A2",
        img: DELFA2,
        contents: [
          {
            title: "Title A2",
            desc: "Present Simple tense - Habit, State",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "ER, IR, OIR and RE verbs",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Pronominal verbs: verbes reflechis and pronominal verbs",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Common verbs, e.g. : come, go, do, know, see, take",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Some Verbs + infinitive: want to, able to, can do",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Have to + infinitive",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Past Simple - events in the past ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Past participles",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Imperfect tense - descriptions in the past (it was, there was)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Imperative - Affirmative and Negative - for instructions and commands",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Near Future Tense  ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Future simple tense -",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Recent Past tense - to have just done sth",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Present Progressive - to be in the process of doing something",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Interrogative adjectives and pronouns",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Adjectives - Position and Agreement ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Possessive adjectives and pronouns   ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Demonstrative pronouns ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Transitive and Intransitive verbs - Direct and Indirect object  ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Relative pronoun 'there' - place ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Relative pronouns - who, that/which ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Prepositions of time: when, while",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Prepositions of place: go to, be at, come from + a place",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Connectors: but, because",
            url: "www.facebook.com",
            img,
          },
        ],
      },
      {
        name: "DELF B1",
        img: DELFB1,
        contents: [
          {
            title: "Title A2",
            desc: "Past tenses",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Future tenses",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Present conditional",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Past conditional ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Reported Speech in present and past",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Agreement in Gender and Number",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Subjunctive",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Passive Voice Describing an action Using 'On'",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Gerund (Ing form) manner condition simultaneous actions",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " IF ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Doubles pronoms  ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Relative pronouns: qui, que, dont, où ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Possessive pronouns",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Demonstrative pronouns",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Limitation: ne ... que",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " Impersonal il",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Expression of time",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " Expression of location prepositions and adverbs of place",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Adverbs of manner: -ly ending ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Comparison",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Negation",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Time sequencers",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Connectors / Conjunctions",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "Conjunction 'pour que' + subjonctif ",
            url: "www.facebook.com",
            img,
          },
        ],
      },
      {
        name: "DELF B2",
        img: DELFB2,
        contents: [
          {
            title: "Title A2",
            desc: "une variété de mots de liaison pour marquer les liens entre les idées de manière efficace",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des articulateurs logiques : expression de l’hypothèse, de l’opposition, de la condition, du but, de la concession",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des verbes + subjonctif ou indicatif (je crois qu’il viendra, je ne crois pas qu’il vienne)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des verbes + subjonctif ou infinitif (il travaille pour réussir, il travaille pour que ses enfants puissent étudier)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "la conjonction + subjonctif ou indicatif (de sorte qu’il puisse venir, de sorte qu’il viendra)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "la conjonction + subjonctif ou infinitif (afin qu’il soit content, afin de venir)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "les formes impersonnelles qui expriment différents degrés de certitude (il est certain que, il est probable que, il semble que, etc.)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des verbes accompagnés de leur préposition (penser à, croire à / en, rêver de, décider de, agir sur, etc.)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des adjectifs accompagnés de prépositions (être heureux de, prêt à, confiant en, remarquable par, etc.)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des noms accompagnés de la préposition “de” + infinitif (avoir le courage de, la joie de, etc.)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "des pronoms relatifs complexes (auquel, parmi lesquels, etc.)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "la mise en relief (c’est...qui, c’est...pour laquelle, etc.)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "le passé simple",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " le passé surcomposé (il a eu terminé)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "le futur antérieur",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " l’infinitif passé (avoir terminé)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " la bonne utilisation de la concordance des temps",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "le participe présent : forme composée pour marquer",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: " l’antériorité (ayant terminé son travail, il est parti) ",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "le subjonctif passé",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "le conditionnel présent et passé : doute, incertitude",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "la modalisation : nuances pour exprimer l’hypothèse, le doute (il est possible que, il est probable que), l’apparence, l’éventualité, l’obligation (il faudrait, tu devrais)",
            url: "www.facebook.com",
            img,
          },
          {
            title: "Title A2",
            desc: "la nominalisation ",
            url: "www.facebook.com",
            img,
          },
        ],
      },
    ],
  },
  Spanish: {
    name: "Spanish Language Course",
    img: AboutUs1,
    tabs: [
      {
        name: "DELEA1",
        img: DELEA1,
        contents: [
          {
            title: "Title A2",
            desc: "Introduction to the language with Verb SER (To be), Introducing ourselves, Alphabet, Numbers,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Frequent questions, Professions, Nouns, Adjectives, Articles, Nationalities,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Questions & Answers to obtain personal information,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Definite articles, Indefinite articles, Colours,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verb ESTAR (to be), Prepositions, Adverbs, Prepositions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Contrast and use of SER and ESTAR verbs, States of mood,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verb TENER (to have), HAY (there is/are), Possessive adjectives,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Vocabulary related to house, family, animals, clothes, weather,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Muy - Mucho (many - much), Time, Weather,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Days of the week, months, seasons,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbs ending in -AR, Direct Object,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbs ending in -ER, Indirect Object,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbs ending in -IR, Verb IR (irregular verb “to go”)",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Irregular verbs, Infinite verbs (future construction), Reflexive verbs",
            url: "www.facebook.com",
            img: imgSpainish,
          },
        ],
      },
      {
        name: "DELEA2",
        img: DELEA2,
        contents: [
          {
            title: "Title A2",
            desc: "Present Continuous Tense, Comparatives, Superlatives",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verb GUSTAR (‘to like’), Pronominal verbs, Expressing Opinions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbal periphrases, Direct and Indirect object, Possessive pronouns",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Pronouns with prepositions, Ordinal numbers, Expressing obligation",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Indefinite adjectives and pronouns, Giving advice, Speaking about plans",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Gradation of adjectives, Introducing Superlative ending in -ISIMO",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Adverbs in Comparative and Superlative form, Interrogative Pronouns",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verb + Indefinite, Collective nouns,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Imperfect future of indicative, Irregular noun constructions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Imperative verb",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Exclamatives: ‘Qué’ and ‘Cuánto’, Frequency adverbs, Vocabulary: travel",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Imperfect past tense,  Verb ‘Soler’, Relative adjectives, Imperative form of ‘nosotros’, Giving directions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Indefinite past tense, Periphrases, Relative adjectives with and without preposition, Media and communication",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Emphatic adjectives and adverbs, Relative pronouns, Contrast of past tenses, Descriptions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Perfect past tense, Irregular participles, Conjunctions: ‘Ya’; ‘aún’ ; ‘todavía’, Environment",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Pluperfect tense, Future perfect tense, adverbs, hospitality.",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Imperfect vs. Indefinite past tenses, Shopping",
            url: "www.facebook.com",
            img: imgSpainish,
          },
        ],
      },
      {
        name: "DELEB1",
        img: DELEB1,
        contents: [
          {
            title: "Title A2",
            desc: "Present Subjunctive, Uses of subjunctive",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Express wish and suggestions, Express remorse",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Express prohibition and give permission, Offer opinions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Subjunctive tense for expressing doubt and possibilities,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Expression of emotions, Using Cuando+subjunctive,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Continuous form of subjunctive, Subjunctive and conditional sentences",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Express complaints and suggestions for something done in the past, Cultural differences",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Conditional tense, Express possibilities in future, Express nostalgia, Discuss holidays",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Past Perfect in subjunctive mode, Express and discuss superstitions",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Uses of Subjunctive, Irregular forms of subjunctive,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Expressing wishes, hopes, possibilities and feelings",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Give orders and opinions, Knowledge about festivals in the Hispanic world",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Conditional tense and uses with subjunctive, future and conditional,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Express future plans, Travel and tourism",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Imperfect in subjunctive mode, Contrast of tenses and modes,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Conditional sentences, Hypothetical situations.",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Express potential situations, Medicine and illness",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Past perfect in subjunctive mode",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Review of subjunctive tenses and uses",
            url: "www.facebook.com",
            img: imgSpainish,
          },
        ],
      },
      {
        name: "DELEB2",
        img: DELEB2,
        contents: [
          {
            title: "Title A2",
            desc: "Introduction of Passive voice, Indirect style present and past,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Relative pronouns, Colloquial expressions used in a work environment",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Types of companies in different sectors, Job interviews, Tourism sector business",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Indirect speech during giving orders",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Current news and political and economical issues",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Temporal reference using ‘desde’, ‘desde hace’, verbs ‘hacer’ and ‘llevar’",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Uses of ‘consigo’ and ‘si mismo’, Debate current life topics",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Review on advanced expressions using all tenses and modes.",
            url: "www.facebook.com",
            img: imgSpainish,
          },
        ],
      },
      {
        name: "DELEC1",
        img: DELEC1,
        contents: [
          {
            title: "Title A2",
            desc: "Review of indicative and subjunctive modes, advanced expressions,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbs ‘costar’, ‘tardar’, ‘llevar’, E-commerce",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Differences of ‘por’ and ‘para’ and uses in advance speech,",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbs with preposition and passive voice, Information technology in daily lives",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Connectors in final and temporal sentences",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Consumerism and shopping, Debates and law",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Uses of concessive  sentences, Direct and Indirect style of speech",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Verbs expressing a starting action, Verb ‘ponerse’, ‘hecharse’ with infinitive",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Sentences with impersonal ‘Se’",
            url: "www.facebook.com",
            img: imgSpainish,
          },
          {
            title: "Title A2",
            desc: "Periphrases and verbs followed by gerund, Advanced constructions using ‘ya’, ‘mira’, ‘no sabes’, ‘no te imaginas’",
            url: "www.facebook.com",
            img: imgSpainish,
          },
        ],
      },
    ],
  },
  Ielts: {
    name: "Crack IELTS, Get to Learn",
    img: AboutUs2,
    tabs: [
      {
        name: "IELTS Academic",
        img: ACADEMIC,
        contents: [
          {
            title: "Title A2",
            desc: "Listening",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Reading",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Writing",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Speaking",
            url: "www.facebook.com",
            img: imgIelts,
          },
        ],
      },
      {
        name: "IELTS General",
        img: GENERAL,
        contents: [
          {
            title: "Title A2",
            desc: "Listening",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Reading",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Writing",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Speaking",
            url: "www.facebook.com",
            img: imgIelts,
          },
        ],
      },
      {
        name: "TOEFL",
        img: TOELF,
        contents: [
          {
            title: "Title A2",
            desc: "Listening",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Reading",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Writing",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Speaking",
            url: "www.facebook.com",
            img: imgIelts,
          },
        ],
      },
      {
        name: "CELPIP",
        img: CELPIP,
        contents: [
          {
            title: "Title A2",
            desc: "Listening",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Reading",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Writing",
            url: "www.facebook.com",
            img: imgIelts,
          },
          {
            title: "Title A2",
            desc: "Speaking",
            url: "www.facebook.com",
            img: imgIelts,
          },
        ],
      },
    ],
  },
};
