import RegisterForm from "components/RegisterForm/RegisterForm";
import React, { useEffect, useState } from "react";
import CourseContent from "../CourseContent/CourseContent";
import CourseSectionTitle from "../CourseSectionTitle/CourseSectionTitle";
import LoginForm from "../LoginForm/LoginForm";

const CourseSection = () => {
  //   const history = useHistory();
  const [open, setOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [mainTitle, setMainTitle] = useState("World of Wordsmith");
  const [subMainTitle, setSubMainTitle] = useState("Students trust us!");

  const [mobile, setMobile] = useState(
    document.documentElement.clientWidth <= 600
  );
  const handleResize = (e) => {
    setMobile(document.documentElement.clientWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //   const { state, dispatch } = useContext(AuthContext);

  return (
    <>
      <CourseSectionTitle main={mainTitle} />
      {!mobile && <div className="border"></div>} <CourseContent />
    </>
  );
};

export default CourseSection;
