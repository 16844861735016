import classes from "./AboutUs.module.scss";
import React from "react";

import overall4 from "../../../assets/overall4.svg";
import overall3 from "../../../assets/overall3.svg";
import overall2 from "../../../assets/overall2.svg";
import overall1 from "../../../assets/overall1.svg";

// overall4.svg;
// import { BOARD_OF_DIRECTORS } from "../../constants";
// import InfoCard from "../../components/InfoCard/InfoCard";
const AboutUs = () => {
  return (
    <div>
      {/* <BgWithText title="About Us" image="bg2" /> */}
      <div className={classes.Content}>
        <div className={classes.Container}>
          <div
            style={{ justifyContent: "space-between", display: "flex" }}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className={classes.TextContainer}
                style={{ flexDirection: "column", display: "flex" }}
              >
                <div className="LargeText bold">
                  <span className="brown">About</span> Us
                </div>
                {/* <p>
                  We are dedicated to helping individuals enhance their language
                  skills and explore the beauty of different languages. Whether
                  you are a beginner or an advanced learner, our platform offers
                  a wide range of resources and tools to support your language
                  journey. We are passionate about languages and believe in the
                  transformative power of learning and connecting through them.
                  Wordsmith is here to inspire, motivate, and empower you as you
                  embark on your language learning adventure.
                </p> */}
                <p>
                  At Wordsmith, we believe that language learning should be
                  accessible, engaging, and enjoyable. We strive to create a
                  supportive and inclusive community where learners from all
                  backgrounds can connect, share knowledge, and inspire each
                  other.
                </p>
                <p>
                  {" "}
                  We are dedicated to providing a platform for language learners
                  and enthusiasts from all over the world to come together and
                  share their passion for language learning. Wordsmith is
                  designed to cater to learners of all levels, whether you are a
                  beginner just starting out on your language learning journey,
                  or an advanced learner looking to refine your skills.
                </p>
              </div>
            </div>
            <div className={classes.ImgContainer}>
              <img className={classes.GraduateImage} src={overall1} alt="" />
            </div>
          </div>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div style={{ alignItems: "center", display: "flex" }}>
              <img className={classes.GraduateImage} src={overall2} alt="" />
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ flexDirection: "column", display: "flex" }}>
                {/* <div className="LargeText bold">
                  <span className="brown">Our Mission</span> {"&"} Goals
                </div> */}
                <p>
                  We offer a wide range of resources and tools to help you
                  achieve your language learning goals, including courses,
                  tutorials, exercises, and interactive language practice. Our
                  team of expert language teachers and linguists are constantly
                  working to develop new and innovative approaches to language
                  learning, and we are committed to staying up-to-date with the
                  latest trends and developments in the field.
                </p>
                <p>
                  At Wordsmith, we believe that learning a new language is about
                  more than just acquiring vocabulary and grammar rules. It is a
                  journey of cultural discovery, a way to connect with people
                  from all over the world, and a path towards personal growth
                  and self-improvement.
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              marginTop: "10vw",
            }}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className={classes.TextContainer}
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <p>Here's what you can expect from Wordsmith:</p>
                <p>
                  Comprehensive Language Resources: We provide a rich collection
                  of resources, including grammar guides, vocabulary lists,
                  interactive exercises, and pronunciation tips.
                </p>
                <p>
                  Interactive Learning Tools: Our platform offers interactive
                  learning tools designed to make language learning more
                  immersive and effective.
                </p>
                {/* <p>
                  Language Exchange and Community: Connect with language
                  enthusiasts from around the world through our language
                  exchange program.
                </p> */}
                <p>
                  Personalized Learning Experience: We understand that everyone
                  learns at their own pace and has unique learning preferences.
                </p>
                <p>
                  Expert Guidance: Our team of language experts and experienced
                  educators are here to support you on your language learning
                  journey.
                </p>
              </div>
            </div>
            <div>
              <img className={classes.GraduateImage} src={overall3} alt="" />
            </div>
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              marginTop: "10vw",
            }}
          >
            <div style={{ alignItems: "center", display: "flex" }}>
              <img
                className={classes.GraduateImage}
                style={{ marginRight: "2vw" }}
                src={overall4}
                alt=""
              />
            </div>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ flexDirection: "column", display: "flex" }}>
                <div className="LargeText bold">
                  <span className="brown">Our Mission</span> {"&"} Goals
                </div>
                <p>
                  The mission and goal of a language course is to help learners
                  acquire the skills and knowledge necessary to communicate
                  effectively in a new language.{" "}
                </p>
                <p>
                  This includes developing the ability to speak, listen, read,
                  and write in the target language, as well as gaining an
                  understanding of the cultural context in which the language is
                  spoken.
                </p>
                <p>
                  Our course is designed to: Foster Language Proficiency,Promote
                  Cultural Understanding,Build Confidence and
                  Motivation,Facilitate Practical Application,Encourage Lifelong
                  Learning.
                </p>
                <p>
                  Ultimately, our mission is to empower individuals with the
                  linguistic and cultural competence needed to connect with
                  others, broaden their horizons, and thrive in an increasingly
                  globalized world.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classes.LeadersContainer}>
          <div>
            <div className="LargeText">
              <span className="brown">Leader</span>ship
            </div>
            <p className={classes.SubText}>Board of Directors</p>
          </div>
          <div className="border"></div>
          <div className={classes.CardsContainer}>
            {BOARD_OF_DIRECTORS.map((item) => (
              <InfoCard
                image={item.image}
                title={item.name}
                subTitle={item.position}
              />
            ))}
          </div>
        </div> */}
        {/* <div className={classes.Footer}>
          <div className={classes.FooterCard}>
            <div className={classes.FooterOverlay}>
              <img src={FooterLogo} alt="" />
            </div>
            <div className={classes.FooterTextContainer}>
              <div>For questions about volunteering or events, contact :</div>
              <div className="semi-bold" style={{ marginTop: "0.6vw" }}>
                Niki Santo, santo@post.harvard.edu
              </div>
            </div>
          </div>
          <div className={classes.OverlayContainer}>
            <div className={classes.OverlayText}>
              Harvard Club Of Silicon Valley
            </div>
            <div className={classes.ThankyouText}>
              <span className="brown">Harvard Club </span>Of Silicon Valley
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
