import React, { useContext, useEffect, useState } from "react";
import classes from "./CourseSectionTitle.module.scss";

const CourseSectionTitle = ({ main, subMain }) => {
  return (
    <div>
      <div className={classes.mainTitle}>{main}</div>
      <div
        style={{
          fontFamily: "inherit",
          fontSize: "2vw",
          fontWeight: 200,
          marginTop: "0.5vw",
          color: "#363636",
        }}
        w
      >
        {subMain}
      </div>
    </div>
  );
};

export default CourseSectionTitle;
