import React from "react";
import { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useParams } from "react-router-dom";

import classes from "./CoursePage.module.scss";
import "react-tabs/style/react-tabs.scss";
import { COURSES } from "constants.js";
import { ChevronDown, ChevronUp } from "react-feather";
import { useMobile } from "hooks/useMobile";
import { Accordion } from "components/Accordion/Accordion";

const CoursePage = () => {
  const { courseName } = useParams();
  const isMobile = useMobile();

  const [currentTab, setCurrentTab] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const currentCourse = COURSES[courseName];

  return (
    <div className={classes.CoursePage}>
      {isMobile ? (
        <Accordion
          courses={currentCourse?.tabs?.map(({ name, contents, img }) => ({
            title: name,
            img,
            body: (
              <div className={classes.List}>
                <div className={classes.subCourses}>
                  {contents.map(({ title, desc, url, img }) => (
                    <div
                      className={classes.listItem}
                      style={{ marginBottom: "1vw" }}
                    >
                      <div className={classes.left}>
                        <div className={classes.profile}>
                          <img src={img} alt="" />
                        </div>
                        <div className={classes.content}>
                          <div className={classes.description}>{desc}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          }))}
        />
      ) : (
        <Tabs
          className={classes.Tabs}
          selectedIndex={currentTab}
          onSelect={setCurrentTab}
        >
          <TabList className={classes.tabsHead}>
            {currentCourse?.tabs.map((value) => (
              <Tab key={value.name} className={classes.tab}>
                <img src={value.img} alt="" style={{ borderRadius: "0.8vw" }} />
                <span className={classes.tabTitle}>{value.name}</span>
              </Tab>
            ))}
          </TabList>

          {currentCourse.tabs.map((values) => (
            <TabPanel key={values.name} className={classes.tabPanel}>
              <div className={classes.List}>
                {(!isMobile || expanded) && (
                  <div className={classes.subCourses}>
                    {values.contents.map(({ title, desc, url, img }) => (
                      <div
                        className={classes.listItem}
                        style={{ marginBottom: "1vw" }}
                      >
                        <div className={classes.left}>
                          <div className={classes.profile}>
                            <img src={img} alt="" />
                          </div>
                          <div className={classes.content}>
                            <div className={classes.description}>{desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </TabPanel>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default CoursePage;
