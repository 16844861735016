import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Switch,
} from "react-router-dom";
import Header from "./components/Header/Header";
import AboutUs from "./components/pages/AboutUs/AboutUs";
import Faculties from "./components/pages/Faculties/Faculties";
import Footer from "./components/pages/Footer/Footer";
import CourseSection from "./components/CoursesSection/CourseSection";
import Home from "./components/pages/Home/Home";
import CoursePage from "pages/CoursePage/CoursePage";
import LoginForm from "components/LoginForm/LoginForm";
import RegisterForm from "components/RegisterForm/RegisterForm";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="" element={<Home />}></Route>
          <Route path="/course/:courseName" element={<CoursePage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />

          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/faculties" element={<Faculties />} />
          {/* <Route element={} /> */}
        </Routes>{" "}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
