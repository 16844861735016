import React from "react";

import classes from "./SuccessStoriesTitle.module.scss";

const SuccessStoriesTitle = ({ main, subMain }) => {
  return (
    <div>
      <div className={classes.title}>{main}</div>
      <div className={classes.subTitle}>{subMain}</div>
    </div>
  );
};

export default SuccessStoriesTitle;
