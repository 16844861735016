import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Testominals.scss";
import { Carousel } from "react-responsive-carousel";
import student1 from "assets/stu1.jpeg";
import student2 from "assets/stu2.jpeg";
import student3 from "assets/stu3.jpeg";
import student4 from "assets/stu4.jpeg";

const TESTIMONIALS = [
  {
    name: "Shirley Fultz",
    id: 1,
    designation: "Designer",
    img: student1,
    desc: `The French online course I took was a game-changer for me. With its structured curriculum and engaging lessons, I went from a beginner to a proficient French speaker.`,
  },
  {
    name: "Aradhana",
    id: 2,
    designation: "SDE",
    img: student2,
    desc: "WordSmith Classes provided me with a flexible and personalized learning experience. The comprehensive resources, live virtual classes, and constant support from French faculty propelled my language skills forward.",
  },
  {
    name: "Saloni",
    id: 3,
    designation: "Product Manager",
    img: student3,
    desc: "Taking Spanish classes from WordSmith was a transformative experience for me. The patient and knowledgeable instructor made learning Spanish fun and interactive.",
  },
  // {
  //   name: "Shirley Fultz",
  //   id: 4,
  //   designation: "Designer",
  //   img: student4,
  //   desc: "It's freeing to be able to catch up on customized news and not be distracted by a social media element on the same site",
  // },
];

export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        {TESTIMONIALS.map(({ name, id, designation, desc, img }) => (
          <div key={id} className="carouselCard">
            <img src={img} alt={name} />
            <div className="carouselDesc">
              <h3>{name}</h3>
              <h4>{designation}</h4>
              <p>{desc}</p>
            </div>
          </div>
        ))}
      </Carousel>
    );
  }
}
