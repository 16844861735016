import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

import classes from "./Accordion.module.scss";

export const Accordion = ({ courses = [] }) => {
  const [currentExpanded, setCurrentExpanded] = useState("DELF A1");
  return (
    <div className={classes.AccordionContainer}>
      {courses.map(({ title, body, img }) => (
        <div className={classes.Accordion}>
          <div
            onClick={() =>
              setCurrentExpanded((prev) => (prev === title ? "" : title))
            }
            className={classes.title}
          >
            <div className={classes.left}>
              <span>
                <img src={img} alt="" />
              </span>
              <span>{title}</span>
            </div>
            {currentExpanded === title ? (
              <ChevronUp size="3.5vw" />
            ) : (
              <ChevronDown size="3.5vw" />
            )}
          </div>
          {currentExpanded === title && (
            <div className={classes.body}>{body}</div>
          )}
        </div>
      ))}
    </div>
  );
};
