import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import randonm1 from "../../assets/random1.svg";

import random2 from "../../assets/random2.svg";

import random3 from "../../assets/random3.svg";
import hifi1 from "../../assets/hifi1.svg";
import hifi2 from "../../assets/hifi2.svg";

import hifi3 from "../../assets/hifi3.svg";
import ielts from "../../assets/ielts.svg";

import Card from "react-bootstrap/Card";

import classes from "./Videos.module.scss";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const VIDEOS = [
  {
    img: randonm1,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
  {
    img: random2,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
  {
    img: random3,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
  {
    img: hifi1,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
  {
    img: hifi2,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
  {
    img: hifi3,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
  {
    img: ielts,
    title: "Card Title",
    link: "https://www.youtube.com/watch?v=mWvyv3xSyP4&ab_channel=LIVComedy",
  },
];

const Videos = ({}) => {
  //   const classes = useStyles();
  return (
    <div
      style={{
        marginTop: "2vw",
      }}
    >
      <Carousel
        responsive={responsive}
        infinite={true}
        draggable={false}
        swipeable={false}
        centerMode={true}
        autoPlay={true}
        autoPlaySpeed={10000}
        keyBoardControl={true}
        showDots={false}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
      >
        {VIDEOS.map(({ title, link, img }) => (
          <Card key={`${img}`} className={classes.Card}>
            <Card.Img className={classes.Img} variant="top" src={img} />
            <Card.Body>
              {/* <Card.Title className={classes.title}>{title}</Card.Title> */}
              {/* <Card.Text>
              Some quick example text to build on the card title and make up.
            </Card.Text> */}
            </Card.Body>
            <Card.Body>
              {/* <Card.Link className={classes.link} href={link}>
                Play Video
              </Card.Link> */}
              {/* <Card.Link  style={{
                  fontFamily: "sans-serif1,
                  fontSize: "1.2vw",
                  color: "#004e8c",
                }} href="#">Another Link</Card.Link> */}
            </Card.Body>
          </Card>
        ))}
      </Carousel>
    </div>
    // </Box>
  );
};

export default Videos;
